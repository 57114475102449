import React from 'react';
import Layout from '../components/layout/Layout';

const Privacy = () => {
  return (
    <Layout>
      <section className="bg-gray-100 py-20">
        <div className="container mx-auto px-4 lg:flex lg:justify-between">
          {/* Side panel for navigation */}
          <div className="hidden lg:block lg:w-1/4 lg:sticky lg:top-24">
            <nav>
              <ul className="bg-white p-4 rounded-lg shadow-md">
                <li className="mb-4">
                  <a href="#privacy" className="text-blue-500 hover:underline">Policy Statement</a>
                </li>
                <li className="mb-4">
                  <a href="#data-policy" className="text-blue-500 hover:underline">Data Policy</a>
                </li>
                <li className="mb-4">
                  <a href="#third-party" className="text-blue-500 hover:underline">Third Party Data</a>
                </li>
              </ul>
            </nav>
          </div>

          {/* Main content */}
          <div className="lg:w-3/4">
            <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-md">
              <h1 id="privacy" className="text-4xl lg:text-5xl xl:text-6xl font-bold mb-10">Privacy Policy</h1>

              <section id="data-policy">
                <h2 className="text-2xl font-bold mb-6">Policy Statement</h2>
                <p>
                At froot™, we hold the privacy and security of your personal information in the highest regard. Our commitment to safeguarding your privacy is at the core of our operations. This Privacy Policy is designed to inform you about how we handle, gather, utilize, disclose, and protect your personal information whenever you engage with our services or interact with our platform.
                </p>
                <br/>
                <p>
                We understand the importance of your personal data and are dedicated to ensuring that your information is handled responsibly and securely. Our aim is to be transparent about the processes involved in collecting and using your data, ensuring your trust in our practices.
                </p>
                <br/>
                <p>
                This Privacy Policy is intended to clarify our practices regarding the collection of your personal data, the purposes for which it is utilized, the conditions under which it may be disclosed to third parties, and the measures we take to safeguard your information.
                </p>
                <br/>
                <p>
                By using our services or interacting with our platform, you acknowledge and agree to the terms outlined in this Privacy Policy. We recommend that you carefully read and familiarize yourself with the contents of this policy to understand how we manage your personal information.
                </p>
                <br/>
                <p>
                This expanded statement emphasizes the company's dedication to protecting user data, highlights the intention of the Privacy Policy, and encourages users to review the document to gain a comprehensive understanding of how their personal information is handled.
                </p>
                {/* Add subsections regarding information collection, usage, security, policy changes, contact details, etc. */}
              </section>
              <section id="privacy">
                <br/>
                <h2 className="text-2xl font-bold mb-6">Data Policy</h2>
                  <p>
                  At froot™, we prioritize the privacy and security of your personal information above all. Our commitment to protecting your privacy is fundamental to our operations. This Privacy Policy outlines our approach to handling, collecting, using, disclosing, and safeguarding your personal information when you use our services or interact with our platform. Crucially, it also details our policies and practices regarding the deletion of your data.
                  </p>
                  <br/>
                  <p>
                  Recognizing the significance of your personal data, we are committed to managing it responsibly and securely. Our goal is to be transparent about our data collection, usage, and particularly the deletion processes, thereby earning your trust.
                  </p>
                  <br/>
                  <p>
                  This Privacy Policy clarifies our practices around the collection and use of your personal data, the circumstances under which it may be shared with third parties, and the steps we take to protect it. Importantly, it also explains your rights regarding the deletion of your personal data and the processes we have in place for safely and permanently removing your information from our systems upon request or in accordance with our data retention policies.
                  </p>
                  <br/>
                  <p>
                  By using our services or interacting with our platform, you acknowledge and agree to the terms outlined in this Privacy Policy, including our data deletion protocols. We recommend that you read this policy thoroughly to understand how we manage and delete your personal information.
                  </p>
                  <br/>
                  <p>
                  This expanded statement reinforces our commitment to safeguarding user data, underlines the purpose of the Privacy Policy, and encourages users to understand the comprehensive measures taken for handling, and when necessary, deleting their personal information.
                  </p>
                  <p>
                  <strong>Deleting Your Data</strong>
                  </p>
                  <p>
                  At froot™, we understand that control over your personal data is paramount. To facilitate this, we have established a straightforward process for you to request the deletion of your personal information. If you wish to have your data removed from our systems, please send an email to <a href="mailto:info@froot.shop">info@froot.shop</a> with your request.
                  </p>
                  <br/>
                  <p>
                  Upon receiving your email, our dedicated team will initiate the process to securely and efficiently delete your personal data from our databases. We are committed to handling your request promptly and will notify you as soon as the deletion has been completed. Our response will confirm the action taken and ensure that you are informed throughout the process.
                  </p>
                  <br/>
                  <p>
                  This procedure is part of our commitment to upholding your rights and maintaining transparency in how we manage your personal information. We respect your decision to control your data and are here to assist in any way necessary to ensure your peace of mind regarding data privacy and security.
                  </p>
                   {/* Add subsections regarding information collection, usage, security, policy changes, contact details, etc. */}
              </section>
              <section id="privacy">
                <br/>
                <h2 className="text-2xl font-bold mb-6">Third Party Data</h2>
                  <p>
                  At froot™, we prioritise the privacy and security of your personal information above all. Our commitment to protecting your privacy is fundamental to our operations. This Privacy Policy outlines our approach to handling, collecting, using, disclosing, and safeguarding your personal information when you use our services or interact with our platform. Crucially, it also details our policies and practices regarding the deletion of your data.
                  </p>
                  <br/>
                  <p>
                  Recognizing the significance of your personal data, we are committed to managing it responsibly and securely. Our goal is to be transparent about our data collection, usage, and particularly the deletion processes, thereby earning your trust.
                  </p>
                  <br/>
                  <p>
                  This Privacy Policy clarifies our practices around the collection and use of your personal data, the circumstances under which it may be shared with third parties, and the steps we take to protect it. Importantly, it also explains your rights regarding the deletion of your personal data and the processes we have in place for safely and permanently removing your information from our systems upon request or in accordance with our data retention policies.
                  </p>
                  <br/>
                  <p>
                  By using our services or interacting with our platform, you acknowledge and agree to the terms outlined in this Privacy Policy, including our data deletion protocols. We recommend that you read this policy thoroughly to understand how we manage and delete your personal information.
                  </p>
                  <br/>
                  <p>
                  This expanded statement reinforces our commitment to safeguarding user data, underlines the purpose of the Privacy Policy, and encourages users to understand the comprehensive measures taken for handling, and when necessary, deleting their personal information.
                  </p>
                  <p>
                  <strong>Deleting Your Data</strong>
                  </p>
                  <p>
                  At froot™, we understand that control over your personal data is paramount. To facilitate this, we have established a straightforward process for you to request the deletion of your personal information. If you wish to have your data removed from our systems, please send an email to <a href="mailto:info@froot.shop">info@froot.shop</a> with your request.
                  </p>
                  <br/>
                  <p>
                  Upon receiving your email, our dedicated team will initiate the process to securely and efficiently delete your personal data from our databases. We are committed to handling your request promptly and will notify you as soon as the deletion has been completed. Our response will confirm the action taken and ensure that you are informed throughout the process.
                  </p>
                  <br/>
                  <p>
                  This procedure is part of our commitment to upholding your rights and maintaining transparency in how we manage your personal information. We respect your decision to control your data and are here to assist in any way necessary to ensure your peace of mind regarding data privacy and security.
                  </p>
                   {/* Add subsections regarding information collection, usage, security, policy changes, contact details, etc. */}
              </section>
              <section id="third-party">
                <br/>
                <h2 className="text-2xl font-bold mb-6">Third Party Data</h2>
                <p>
                  At froot™, your privacy is our priority. We are dedicated to safeguarding your personal information and maintaining transparency in its use. This section outlines our Privacy Policy, detailing how we handle, collect, use, store, and share your data when you use our services or interact with our platform.
                </p>
                <br/>
                <p>
                  Our Privacy Policy is comprehensive and clear, addressing how we manage your data, including the circumstances under which it may be shared with third parties, and the measures we take to protect it. For more information, please visit our detailed Privacy Policy at <a href="https://www.froot.co.za/privacy">www.froot.co.za/privacy</a>.
                </p>
                <br/>
                <p>
                  In compliance with Google's Limited Use Requirements, our application respects your privacy and only uses your data in ways that are essential for the services we provide. Our policy also describes our practices in accordance with these requirements.
                </p>
                <br/>
                <strong>Control Over Your Data</strong>
                <p>
                  We recognize your right to control your personal data. Our Privacy Policy, accessible on our verified domain, ensures easy access and clarity for all users. It includes information on your rights regarding data deletion and our procedures for safely and permanently removing your data from our systems upon request or as per our data retention policies.
                </p>
                <br/>
                <p>
                  To request the deletion of your personal data, please email us at <a href="mailto:info@froot.shop">info@froot.shop</a>. Our dedicated team will respond promptly, initiating a secure and efficient data deletion process and keeping you informed every step of the way.
                </p>
                <br/>
                <p>
                  We are committed to ensuring that our application and its privacy practices are ready for production and meet all verification requirements. For any further information or queries, feel free to contact us.
                </p>
                <br/>
                <h3 className="text-xl font-bold">Information Collection and Usage</h3>
                <p>
                  Our platform collects information to provide better services to our users. This includes basic user information such as name, email address, and payment information, as well as usage data that helps us improve user experience.
                </p>
                <br/>
                <h3 className="text-xl font-bold">Data Security</h3>
                <p>
                  We employ robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. These include internal reviews of our data collection, storage, processing practices, and security measures.
                </p>
                <br/>
                <h3 className="text-xl font-bold">Policy Changes</h3>
                <p>
                  Our Privacy Policy may change from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice.
                </p>
                <br/>
                <h3 className="text-xl font-bold">Contact Information</h3>
                <p>
                  For any questions or concerns regarding our privacy practices, please contact us at <a href="mailto:info@froot.shop">info@froot.shop</a>.
                </p>
                <br/>
                {/* Additional subsections can be added as required */}
              </section>

              <p className="text-gray-600 text-center mt-8">Premium Tech Accessories.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Privacy;
